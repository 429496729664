// extracted by mini-css-extract-plugin
export var additionalServiceBottomItem = "style-module--additional-service-bottom-item--7d750";
export var additionalServiceBottomItemDesc = "style-module--additional-service-bottom-item-desc--99218";
export var additionalServiceBottomItemTitle = "style-module--additional-service-bottom-item-title--8cceb";
export var additionalServiceItem = "style-module--additional-service-item--3b6a0";
export var additionalServiceItemButton = "style-module--additional-service-item-button--e5a72";
export var additionalServiceItemDescription = "style-module--additional-service-item-description--c7a37";
export var additionalServiceItemImg = "style-module--additional-service-item-img--7ab9d";
export var additionalServiceItemText = "style-module--additional-service-item-text--31b6b";
export var additionalServiceItemTitle = "style-module--additional-service-item-title--6e4fa";
export var additionalServiceItems = "style-module--additional-service-items--55f12";
export var background = "style-module--background--079ea";
export var backgroundImg = "style-module--background-img--d1b31";
export var containerAdditionalService = "style-module--container-additional-service--8ea9f";
export var containerHowBecomePartner = "style-module--container-how-become-partner--6192c";
export var containerServiceFor = "style-module--container-service-for--8cfe9";
export var containerServices = "style-module--container-services--66fed";
export var containerTop = "style-module--container-top--21122";
export var containerWhoBecomePartner = "style-module--container-who-become-partner--7cdbc";
export var description = "style-module--description--66512";
export var howBecomePartnerLine = "style-module--how-become-partner-line--d279a";
export var howBecomePartnerNumContainer = "style-module--how-become-partner-num-container--2e72c";
export var howBecomePartnerNumImg = "style-module--how-become-partner-num-img--e5c2d";
export var howBecomePartnerNumText = "style-module--how-become-partner-num-text--37f0c";
export var howBecomePartnerNumText1 = "style-module--how-become-partner-num-text-1--4d204";
export var howBecomePartnerNumText2 = "style-module--how-become-partner-num-text-2--3bc74";
export var howBecomePartnerNumText3 = "style-module--how-become-partner-num-text-3--00158";
export var howBecomePartnerNums = "style-module--how-become-partner-nums--d04f7";
export var howBecomePartnerTitle = "style-module--how-become-partner-title--9a69f";
export var serviceBottom = "style-module--service-bottom--9171b";
export var serviceBottomBtn = "style-module--service-bottom-btn--75679";
export var serviceBottomDesc = "style-module--service-bottom-desc--993fa";
export var serviceItem = "style-module--service-item--2d0d8";
export var serviceItemDescription = "style-module--service-item-description--7ca9b";
export var serviceItemImg = "style-module--service-item-img--8d511";
export var serviceItemTitle = "style-module--service-item-title--4416e";
export var text = "style-module--text--ae3ce";
export var title = "style-module--title--ce129";
export var tryButton = "style-module--try-button--d1fb7";
export var whoBecomePartnerBottomText = "style-module--who-become-partner-bottom-text--ae0fe";
export var whoBecomePartnerDescription = "style-module--who-become-partner-description--985a2";
export var whoBecomePartnerDescriptionAdditional = "style-module--who-become-partner-description-additional--bfa60";
export var whoBecomePartnerDescriptionBottom = "style-module--who-become-partner-description-bottom--51cb0";
export var whoBecomePartnerDescriptionGeneral = "style-module--who-become-partner-description-general--604c3";
export var whoBecomePartnerDescriptionIndividual = "style-module--who-become-partner-description-individual--ebeef";
export var whoBecomePartnerDescriptionOrganisation = "style-module--who-become-partner-description-organisation--ead62";
export var whoBecomePartnerGradient = "style-module--who-become-partner-gradient--4acfe";
export var whoBecomePartnerTable = "style-module--who-become-partner-table--3a6b7";
export var whoBecomePartnerTableColumn = "style-module--who-become-partner-table-column--ff88b";
export var whoBecomePartnerTableColumnHead = "style-module--who-become-partner-table-column-head--25c68";
export var whoBecomePartnerTableColumnItem = "style-module--who-become-partner-table-column-item--7d311";
export var whoBecomePartnerTableIndividual = "style-module--who-become-partner-table-individual--7c7fc";
export var whoBecomePartnerTableMobile = "style-module--who-become-partner-table-mobile--f3964";
export var whoBecomePartnerTableMobileColumn = "style-module--who-become-partner-table-mobile-column--1ad48";
export var whoBecomePartnerTableMobileColumnHead = "style-module--who-become-partner-table-mobile-column-head--2d18d";
export var whoBecomePartnerTableMobileColumnItem = "style-module--who-become-partner-table-mobile-column-item--ebc8f";
export var whoBecomePartnerTableMobileIndividual = "style-module--who-become-partner-table-mobile-individual--faa15";
export var whoBecomePartnerTableMobileOrganisation = "style-module--who-become-partner-table-mobile-organisation--dcb33";
export var whoBecomePartnerTableOrganisation = "style-module--who-become-partner-table-organisation--a3905";
export var whoBecomePartnerTitle = "style-module--who-become-partner-title--db78e";