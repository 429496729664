import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import * as styles from "./style.module.scss";
import {StaticImage} from "gatsby-plugin-image";

import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {BackgroundPosterCt, ButtonOfScrollCt, Layout} from "@components";

import styled from "styled-components";

import num1Img from "@images/outsourcing/num-1.png";
import num2Img from "@images/outsourcing/num-2.png";
import num3Img from "@images/outsourcing/num-3.png";

import {useWindowSize} from "@tools/hooks";

import sWalletImg from "@images/outsourcing/service-wallet.png";
import sDocImg from "@images/outsourcing/service-doc.png";
import sUpperImg from "@images/outsourcing/service-upper.png";
import sFnsImg from "@images/outsourcing/service-fns.png";
import sPrivateImg from "@images/outsourcing/service-private.png";
import sSettingsImg from "@images/outsourcing/service-settings.png";
import sSupportImg from "@images/outsourcing/service-support.png";

const LandingSiteQuiz: React.FC<{}> = ({}) => {

    useEffect(() => {
        const mainUrl = "/promo/iframe-quiz/landing_site";
        const urlParams = window.location.search;
        const iframeUrl = mainUrl + urlParams;
        const quizFrame: any = document.getElementById('quiz');
        quizFrame.src = iframeUrl;
        quizFrame.addEventListener('load', () => {
            try {
                quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                quizFrame.style.height = '510px';
            }
        })
        window.addEventListener('resize', () => {
            try {
                quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                quizFrame.style.height = '510px';
            }
        })
    }, [])

    return <iframe style={{width: '100%', border: 'unset', minWidth: '100vw'}} id="quiz"></iframe>
}

const OutsourcingPage = () => {
    const {width} = useWindowSize();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Аутсорсинг для самозанятых: выгодный аутсорсинг персонала
                </title>
                <meta
                    name="description"
                    content="Работа с персоналом на аутсорсинге в качестве самозанятых через сервис «Бизнес»: экономьте деньги и время работая с подрядчиками на аутсорсе."
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/outsourcing"
                />
            </Helmet>
            <Layout showMenu={false} smallFooter={true}>
                <div className={styles.containerTop}>
                    <BackgroundPosterCt/>
                    <div className={styles.text}>
                        <TypographyUI.H1
                            swichPoint={LAPTOP_MOBILE_POINTER}
                            className={styles.title}
                            variant="h1"
                        >Работайте с самозанятыми на аутсорсе — экономьте время и деньги
                        </TypographyUI.H1>

                        <TypographyUI.Body1Regular
                            swichPoint={LAPTOP_MOBILE_POINTER}
                            className={styles.description}
                        >Подключите сервис «Бизнес»: оформим самозанятость исполнителям, подготовим договоры, проведем
                            выплаты и отправим чеки
                        </TypographyUI.Body1Regular>

                        <ButtonUI $w="240px" className={styles.tryButton} onClick={() => {
                            document.querySelector('#quizSection')?.scrollIntoView({behavior: 'smooth'});
                        }}>Попробовать</ButtonUI>
                    </div>

                    <div className={styles.background}>
                        <StaticImage
                            className={styles.backgroundImg}
                            src="../../images/outsourcing/background.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={576}
                            height={523}
                            layout="fixed"
                            objectFit="contain"
                            alt="background-img"
                        />
                    </div>
                </div>

                <ButtonOfScrollCt id="additionalService" $w="100%" $mt="120px"/>


                <div id="additionalService" className={styles.containerAdditionalService}>
                    <TypographyUI.H2 className={styles.title}>
                        Как это работает?
                    </TypographyUI.H2>
                    <div className={styles.additionalServiceItems}>
                        <div className={styles.additionalServiceItem}>
                            <div className={styles.additionalServiceItemImg}>
                                <StaticImage
                                    src="../../images/outsourcing/how-work.png"
                                    loading="eager"
                                    placeholder="none"
                                    layout="constrained"
                                    objectFit="contain"
                                    alt="how-work"
                                />
                            </div>
                            <div className={styles.additionalServiceItemText}>
                                <TypographyUI.Body1Regular
                                    className={styles.additionalServiceItemDescription}
                                >
                                    В штате аутсорс-агентства «Сделаем за вас» работают 20 сотрудников. Остальные 200 —
                                    исполнители по договорам ГПХ. С каждым таким исполнителем «Сделаем за вас» заключает
                                    договор ГПХ, платит НДФЛ и страховые взносы. Или работает без договора и переживает
                                    за налоговые проверки.

                                </TypographyUI.Body1Regular>

                                <div className={styles.additionalServiceBottomItem}>
                                    <TypographyUI.Body1Medium className={styles.additionalServiceBottomItemTitle}>Физические
                                        лица:</TypographyUI.Body1Medium>
                                    <TypographyUI.Body2Regular className={styles.additionalServiceBottomItemDesc}>НДФЛ
                                        13% OПC 22% OMC 5,1%, ВНиМ 2,9% — 43%</TypographyUI.Body2Regular>
                                </div>


                                <TypographyUI.Body1Regular
                                    className={styles.additionalServiceItemDescription}
                                >
                                    Самозанятый тоже работает по договору подряда или оказания услуг, но налоги
                                    платит за себя сам. А с документами поможет разобраться «Бизнес»
                                </TypographyUI.Body1Regular>

                                <div className={styles.additionalServiceBottomItem}>
                                    <TypographyUI.Body1Medium
                                        className={styles.additionalServiceBottomItemTitle}>Самозанятые:</TypographyUI.Body1Medium>
                                    <TypographyUI.Body2Regular className={styles.additionalServiceBottomItemDesc}>НПД
                                        6%</TypographyUI.Body2Regular>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <div id="serviceFor" className={styles.containerServiceFor}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.title}
                    >
                        Почему работать с самозанятыми на аутсорсе выгодно
                    </TypographyUI.H2>
                </div>

                <div className={styles.containerServices}>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-wallet"
                            src={sWalletImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Платите только за услуги
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            По закону за каждый договор ГПХ с исполнителем аутсорс-компания должна заплатить 13% НДФЛ и
                            30% страховых взносов. Самозанятый оплачивает только 6% налога самостоятельно
                        </TypographyUI.Body1Regular>
                    </div>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-doc"
                            src={sDocImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Подтверждайте расходы на исполнителей
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Если вы работаете на УСН «Доходы минус расходы», расчеты с самозанятым персоналом
                            учитываются в «Расходы». Подготовим и отправим все подтверждающие документы для налоговой
                        </TypographyUI.Body1Regular>
                    </div>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-upper"
                            src={sUpperImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Оптимизируйте нагрузку на бухгалтерию
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Документооборот, страховые взносы, расчеты по каждому исполнителю — серьезная нагрузка на
                            бухгалтерию. Работая с самозанятыми, вы можете подключить сервис-помощник, автоматизировать
                            все процессы и сэкономить на услугах бухгалтерии
                        </TypographyUI.Body1Regular>
                    </div>

                    <div className={styles.serviceBottom}>
                        <ButtonUI.Secondary onClick={() => {
                            document.querySelector('#quizSection')?.scrollIntoView({behavior: 'smooth'});
                        }}
                                            className={styles.serviceBottomBtn}
                                            $w="240px"
                        >
                            Узнать подробнее
                        </ButtonUI.Secondary>
                        <TypographyUI.Body2Regular className={styles.serviceBottomDesc}>
                            Самозанятым персоналом на аутсорсе может стать упаковщик, курьер, сортировщик, грузчик,
                            комплектовщик и другие специальности подряда. Ограничений нет, главное — правильно составить
                            договор. За пять лет работы в этой сфере мы изучили все нюансы налогового законодательства и
                            готовы поделиться этим опытом с вами
                        </TypographyUI.Body2Regular>
                    </div>

                </div>


                <div id="serviceFor" className={styles.containerServiceFor}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.title}
                    >
                        Как сервис «Бизнес» помогает аутсорс-компаниям
                    </TypographyUI.H2>
                </div>

                <div className={styles.containerServices}>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-doc"
                            src={sDocImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Возьмем на себя документооборот
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Поможем бухгалтерии не запутаться в актах, договорах и чеках. Выберите исполнителя и
                            подпишите договор — все остальное сделаем мы
                        </TypographyUI.Body1Regular>
                    </div>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-fns"
                            src={sFnsImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Поможем оформить самозанятость
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Большинство персонала не знает, как оформить самозанятость, или не может по техническим
                            причинам. Высылаем инструкцию и консультируем 24/7
                        </TypographyUI.Body1Regular>
                    </div>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-private"
                            src={sPrivateImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Не отдадим ваших исполнителей
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Мы не регистрируем ваш персонал в маркетплейсе и не делимся контактами. Ваши конкуренты не
                            смогут переманить специалистов к себе
                        </TypographyUI.Body1Regular>
                    </div>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-settings"
                            src={sSettingsImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Подключаем персонал к сервису
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Для упрощенной регистрации не нужны фото паспортов и заявителя. Бесплатно сделаем
                            электронную подпись для каждого исполнителя, проверим статус через ФНС
                        </TypographyUI.Body1Regular>
                    </div>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-wallet"
                            src={sWalletImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Выплачиваем на любые российские карты
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Исполнитель может получить выплату на любую российскую карту: свою или родственника. Это
                            законно, достаточно указать номер карты в личном кабинете
                        </TypographyUI.Body1Regular>
                    </div>
                    <div className={styles.serviceItem}>
                        <img
                            height={56}
                            width={56}
                            className={styles.serviceItemImg}
                            alt="service-support"
                            src={sSupportImg}
                        />
                        <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                            Будем на связи
                        </TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular
                            className={styles.serviceItemDescription}
                        >
                            Предоставляем каждому клиенту персонального менеджера, который всегда на связи. Он поможет
                            настроить сервис, составить договор и разобраться в законах
                        </TypographyUI.Body1Regular>
                    </div>
                </div>


                <div className={styles.containerHowBecomePartner}>
                    <TypographyUI.H2 className={styles.howBecomePartnerTitle}>
                        Как стать нашим партнером
                    </TypographyUI.H2>

                    <div className={styles.howBecomePartnerNums}>
                        <div className={styles.howBecomePartnerLine}></div>
                        <div className={styles.howBecomePartnerNumContainer}>
                            <img
                                alt="num1"
                                className={styles.howBecomePartnerNumImg}
                                src={num1Img}
                            />
                            <TypographyUI.Body2Medium
                                className={[styles.howBecomePartnerNumText, styles.howBecomePartnerNumText1].join(' ')}>
                                Заполнить заявку
                            </TypographyUI.Body2Medium>
                        </div>
                        <div className={styles.howBecomePartnerNumContainer}>
                            <img
                                alt="num2"
                                className={styles.howBecomePartnerNumImg}
                                src={num2Img}
                            />
                            <TypographyUI.Body2Medium
                                className={[styles.howBecomePartnerNumText, styles.howBecomePartnerNumText2].join(' ')}>Получить
                                бесплатную консультацию с нашим менеджером и принять решение</TypographyUI.Body2Medium>
                        </div>
                        <div className={styles.howBecomePartnerNumContainer}>
                            <img
                                alt="num3"
                                className={styles.howBecomePartnerNumImg}
                                src={num3Img}
                            />
                            <TypographyUI.Body2Medium
                                className={[styles.howBecomePartnerNumText, styles.howBecomePartnerNumText3].join(' ')}>Подключиться
                                к сервису и добавить исполнителей</TypographyUI.Body2Medium>
                        </div>
                    </div>
                </div>

                <SectionBoxSd id="quizSection">

                    <LandingSiteQuiz/>

                </SectionBoxSd>
            </Layout>
        </>
    );
};

export default OutsourcingPage;

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  position: relative;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 20px;
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 20px;
  }
`;
